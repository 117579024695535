import CONFIG from "constants/config";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecentSSCases } from "redux/actions/ssRecentCases";
import LABEL from "constants/label";

const { apiUrl, xApiKey, elasticConfig } = CONFIG;

const { CASE_QUERY_PARAMS } = LABEL;

const useFetchRecentCases = (profile, isErrorApi) => {
  const dispatch = useDispatch();

  const [recentCases, setRecentCases] = useState([]);

  const { ssRecentCases } = useSelector((state) => state);

  const { ssRecentCasesLoading } = useSelector((state) => state);

  const [totalCases, setTotalCases] = useState(null);

  useEffect(() => {
    if (profile?.hrId && !isErrorApi) {
      dispatch(
        getRecentSSCases({
          endpointUrl: `${apiUrl.enrichURL}genai-enbl-retrieval/v1/search/`,
          data: {
            hrid: profile.hrId,
            query: "",
            data_repository: CASE_QUERY_PARAMS.ELASTIC_DATA_REPOSITORY,
            method: CASE_QUERY_PARAMS.ELASTIC_SEARCH_METHOD,
            from_n: 1,
            top_n: 4,
            elastic_cluster: elasticConfig.clusterType,
            consumer_options: {
              consumer_key: "case",
              data_source: "case",
              workspace_id: elasticConfig.workSpaceId
            },
            search_options: {
              filter_clauses: [{ filter_fields: [], date_fields: [] }],
              advancesearch: [],
              facets: [],
              default_sort: true
            }
          },
          config: {
            headers: {
              "x-api-key": `${xApiKey.elaseticCases}`,
              "content-type": "application/json"
            }
          }
        })
      );
    }
  }, [profile?.hrId, dispatch]);

  useEffect(() => {
    if (ssRecentCases.number_of_results) setTotalCases(ssRecentCases.number_of_results.toLocaleString());
    else if (!ssRecentCasesLoading && ssRecentCases.number_of_results === undefined) setTotalCases("0");
    else setTotalCases("--");
  }, [recentCases, ssRecentCases]);

  useEffect(() => {
    setRecentCases(ssRecentCases?.results);
  }, [ssRecentCases]);

  return {
    ssRecentCasesLoading,
    recentCases,
    totalCases
  };
};

export default useFetchRecentCases;
